import { apiAuthQuestion, apiAuthAnswer } from '@/api/auth'
import { required, email } from '@vuelidate/validators'
import { RESET_PASSWORD_TOKEN_LIFETIME } from '@/constants'
import { notCreditCard } from "@shared/utils/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({
    v$: useVuelidate(),
  }),
  data: () => ({
    passwordVisible: false,
    email: '',
    loading: false,
    errors: {}
  }),
  validations() {
    return {
      email: { required },
    }
  },
  methods: {
    handleRequest() {
      this.v$.$touch()
      if (this.v$.$invalid) return
      this.handleRequestAnswer()
    },
    async handleRequestAnswer() {
      const data = {
        username: this.email,
      }
      try {
        this.loading = true
        const res = await apiAuthAnswer(data)
        if (res.status === 'SUCCESS') {
          this.setModal('ResetPassword')
          this.$msg('Password reset request successfully, we will send secret code for change password - please check your email!', 1)
          localStorage.setItem('resetRequest', JSON.stringify(Date.now()))
          localStorage.setItem('resetRequestUsername', this.email)
        }
      } catch (err) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors }
      }
      finally {
        this.loading = false
        this.$store.dispatch('app/setErrors')
      }
    }
  }
}
