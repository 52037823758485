import { apiSignUp } from '@/api/auth'
import { required, email } from '@vuelidate/validators'
import {
  password,
  general,
  name,
  notCreditCard
} from "@shared/utils/validators";
import { unescapeString } from '@/utils/index'
import {useVuelidate} from "@vuelidate/core";

export default {
  setup: () => ({v$: useVuelidate()}),
  data: () => ({
    passwordVisible: false,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    loading: false,
    errors: {}
  }),
  validations: () => {
    return {
      firstName: { required, name, notCreditCard },
      lastName: { required, name, notCreditCard },
      email: { required, email },
      password: { required, password },
    }
  },
  computed: {
    isPasswordVisible() {
      return this.passwordVisible ? 'text' : 'password'
    },
  },
  methods: {
    unescapeString,
    async handleSignUp() {
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
      }
      this.v$.$touch()
      if (this.v$.$invalid) return
      try {
        this.loading = true
        const res = await apiSignUp(data)
        this.$auth.login(res.access_token, res.refresh_token, res.expires_in, res.token_type)
        this.setModal()
        if (res.access_token) {
          this.$msg('Welcome back! You are now signed in!', 1)
        }
      } catch (err) {
        this.errors = { ...this.errors, ...this.$store.state.app.errors }
        this.$store.dispatch('app/setErrors')
      }
      finally {
        this.loading = false
      }
    },
    openSignInModal() {
      this.setModal('Login')
    },
  }
}
